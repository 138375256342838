import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Machine } from "@/config/Modules";

const MachineModule = namespace(Machine.store);

@Component({})
export class MachineStoreMixin extends Vue {
  /**
   * Actions
   */
  @MachineModule.Action("create")
  protected createMachineItemAction: any;

  @MachineModule.Action("findOne")
  protected findOneMachineItemAction: any;

  @MachineModule.Action("update")
  protected updateMachineItemAction: any;

  @MachineModule.Action("findAll")
  protected findAllMachineItemsAction: any;

  @MachineModule.Action("resetSave")
  protected resetSaveMachineAction: any;

  @MachineModule.Action("resetList")
  protected resetListMachineAction: any;

  @MachineModule.Action("del")
  protected delMachineItemAction: any;

  @MachineModule.Action("findAllSubresource")
  protected findAllSubresourceMachineItemsAction: any;

  @MachineModule.Action("setDataList")
  protected setDataListMachineAction: any;

  @MachineModule.Action("upload")
  protected uploadMachineAction: any;

  /**
   * Getters
   */
  @MachineModule.Getter("getError")
  protected getMachineError: any;

  @MachineModule.Getter("getIsLoading")
  protected getMachineIsLoading: any;

  @MachineModule.Getter("getIsSaving")
  protected getMachineIsSaving: any;

  @MachineModule.Getter("getDataItem")
  protected getMachineItem: any;

  @MachineModule.Getter("getSuccess")
  protected getMachineSuccess: any;

  @MachineModule.Getter("getDataList")
  protected getMachineList: any;

  @MachineModule.Getter("getTotal")
  protected getMachineTotal: any;

  @MachineModule.Getter("getDeletedSuccess")
  protected getMachineDeletedSuccess: any;

  @MachineModule.Getter("getValidationErrors")
  protected getMachineValidationErrors: any;

  /**
   * Mutations
   */
  @MachineModule.Mutation("IS_LOADING")
  protected setMachineLoading: any;

  @MachineModule.Mutation("SET_IS_SAVING")
  protected setMachineIsSaving: any;

  @MachineModule.Mutation("SET_ERROR")
  protected setMachineError: any;

  @MachineModule.Mutation("SET_SUCCESS")
  protected setMachineSuccess: any;

  @MachineModule.Mutation("SET_DATA_ITEM")
  protected setMachineItem: any;

  @MachineModule.Mutation("SET_DATA_LIST")
  protected setMachineList: any;

  @MachineModule.Mutation("SET_TOTAL")
  protected setMachineTotal: any;

  @MachineModule.Mutation("SET_DELETED_SUCCESS")
  protected setMachineDeletedSuccess: any;

  @MachineModule.Mutation("SET_VALIDATION_ERRORS")
  protected setMachineValidationErrors: any;

  // Additional...
}
