
import { Component, Prop, Watch } from "vue-property-decorator";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import IMachine from "@/interfaces/IMachine";
import AddressesForm from "@/components/forms/common/AddressesForm.vue";
import Json from "../../../helper/Json";
import { mixins } from "vue-class-component";
import { MachineStoreMixin } from "@/mixins/Stores/MachineStoreMixin.ts";
import ICustomer from "@/interfaces/ICustomer";
import Str from "@/helper/Str";
import CustomerSelect from "@/components/form_inputs/CustomerSelect.vue";
import ResponsibleEmployeeSelect from "@/components/form_inputs/ResponsibleEmployeeSelect.vue";
import IUser from "@/mixins/interface/IUser";
import IProviderCompany from "@/interfaces/IProviderCompany";
import Consts from "@/helper/Consts";
import ProviderCompanySelect from "@/components/form_inputs/ProviderCompanySelect.vue";

@Component({
  computed: {
    Str() {
      return Str;
    },
    Json() {
      return Json;
    },
  },
  components: {
    ProviderCompanySelect,
    ResponsibleEmployeeSelect,
    CustomerSelect,
    AddressesForm,
    SaveButton,
    DefaultForm,
  },
  mixins: [MachineStoreMixin, FormMixin],
})
export default class MachineForm extends mixins(MachineStoreMixin) {
  protected name = "MachineForm.vue";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: IMachine;

  protected id?: number;

  protected machine = "";
  protected get customerId(): number | null {
    return this.customer?.id ?? null;
  }

  protected customer: ICustomer | null = null;

  protected get responsibleEmployeeUserId(): number | null {
    return this.responsibleEmployee?.id ?? null;
  }

  protected responsibleEmployee: IUser | null = null;
  protected osType = "";
  protected providerCompanyId = 0;

  protected get providerCompany(): IProviderCompany | null {
    return Consts.getProviderCompanyById(this.providerCompanyId);
  }

  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;

  protected osTypes: Array<string> = ["Windows", "Linux", "MacOS", "Other"];

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: IMachine) {
    if (data) {
      this.setFormValues(data);
    }
  }

  public getFormValues(): IMachine {
    let machine: IMachine = {
      machine: this.machine,
      customer_id: this.customerId,
      responsible_employee_user_id: this.responsibleEmployeeUserId,
      os_type: this.osType,
      provider_company_id: this.providerCompanyId,
    };
    if (this.data && this.data.id) {
      machine.id = this.id;
    }
    return machine;
  }

  protected setFormValues(data: IMachine): void {
    if (data) {
      this.id = data.id;
      this.machine = data.machine;
      this.customer = data.customer ?? null;
      this.responsibleEmployee = data.responsible_employee ?? null;
      this.osType = data.os_type;
      this.providerCompanyId = data.provider_company_id;
      this.createdAt = data.created_at;
      this.updatedAt = data.updated_at;
    }
  }

  protected init() {}
}
