import { Component, Vue } from "vue-property-decorator";
import AppConfig from "@/config/AppConfig";
import { namespace } from "vuex-class";

const UsersModule = namespace(AppConfig.USER_STORE);

@Component({})
export class UserStoreMixin extends Vue {
  /**
   * Actions
   */
  @UsersModule.Action("create")
  protected createUserItemAction: any;

  @UsersModule.Action("findOne")
  protected findOneUserItemAction: any;

  @UsersModule.Action("update")
  protected updateUserItemAction: any;

  @UsersModule.Action("findAll")
  protected findAllUserItemsAction: any;

  @UsersModule.Action("resetSave")
  protected resetSaveUserAction: any;

  @UsersModule.Action("resetList")
  protected resetListUserAction: any;

  @UsersModule.Action("del")
  protected delUserItemAction: any;

  @UsersModule.Action("findAllSubresource")
  protected findAllSubresourceUserItemsAction: any;

  @UsersModule.Action("setDataList")
  protected setDataListUserAction: any;

  @UsersModule.Action("upload")
  protected uploadUserAction: any;

  /**
   * Getters
   */
  @UsersModule.Getter("getError")
  protected getUserError: any;

  @UsersModule.Getter("getIsLoading")
  protected getUserIsLoading: any;

  @UsersModule.Getter("getDataItem")
  protected getUserItem: any;

  @UsersModule.Getter("getSuccess")
  protected getUserSuccess: any;

  @UsersModule.Getter("getDataList")
  protected getUserList: any;

  @UsersModule.Getter("getTotal")
  protected getUserTotal: any;

  @UsersModule.Getter("getDeletedSuccess")
  protected getUserDeletedSuccess: any;

  /**
   * Mutations
   */
  @UsersModule.Mutation("IS_LOADING")
  protected setUserLoading: any;

  @UsersModule.Mutation("SET_ERROR")
  protected setUserError: any;

  @UsersModule.Mutation("SET_SUCCESS")
  protected setUserSuccess: any;

  @UsersModule.Mutation("SET_DATA_ITEM")
  protected setUserItem: any;

  @UsersModule.Mutation("SET_DATA_LIST")
  protected setUserList: any;

  @UsersModule.Mutation("SET_TOTAL")
  protected setUserTotal: any;

  @UsersModule.Mutation("SET_DELETED_SUCCESS")
  protected setUserDeletedSuccess: any;
}
