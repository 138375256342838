
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import IProviderCompany from "@/interfaces/IProviderCompany";
import { ProviderCompanies } from "@/config/Consts";

@Component({})
export default class BooleanSelect extends Vue {
  @Prop({ required: false, default: false })
  protected isExpanded!: boolean;

  @Prop({ required: false, default: "" })
  protected placeholder!: string;

  @Prop({ required: false, default: "general.yes" })
  protected positiveI18nKey!: string;

  @Prop({ required: false, default: "general.no" })
  protected negativeI18nKey!: string;

  protected get selectData(): Array<IProviderCompany> {
    return ProviderCompanies;
  }

  @VModel()
  protected selected!: number;
}
