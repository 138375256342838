var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getMachineIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$tc('modules.base_data.machine.machine', 1))
                ),"type":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.machine
                  ? 'is-danger'
                  : '',"message":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.machine
                  ? _vm.getMachineValidationErrors.machine
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.base_data.machine.placeholder.machine')),"required":""},model:{value:(_vm.machine),callback:function ($$v) {_vm.machine=$$v},expression:"machine"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$t('modules.base_data.machine.provider_company'))
                ),"type":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.provider_company_id
                  ? 'is-danger'
                  : '',"message":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.provider_company_id
                  ? _vm.getMachineValidationErrors.provider_company_id
                  : ''}},[_c('ProviderCompanySelect',{attrs:{"is-expanded":true},model:{value:(_vm.providerCompanyId),callback:function ($$v) {_vm.providerCompanyId=$$v},expression:"providerCompanyId"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$t('modules.base_data.machine.os_type'))
                ),"type":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.os_type
                  ? 'is-danger'
                  : '',"message":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.os_type
                  ? _vm.getMachineValidationErrors.os_type
                  : ''}},[_c('b-select',{attrs:{"icon":"fas fa-jfals","expanded":""},model:{value:(_vm.osType),callback:function ($$v) {_vm.osType=$$v},expression:"osType"}},_vm._l((_vm.osTypes),function(osType,osTypeIdx){return _c('option',{key:'os-type-select-os-type-idx-' + osTypeIdx,domProps:{"value":osType}},[_vm._v(" "+_vm._s(osType)+" ")])}),0)],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$t('modules.base_data.machine.customer'))
                ),"type":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.customer
                  ? 'is-danger'
                  : '',"message":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.customer
                  ? _vm.getMachineValidationErrors.customer
                  : ''}},[_c('CustomerSelect',{attrs:{"is-expanded":true},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$t('modules.base_data.machine.responsible_employee'))
                ),"type":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.responsible_employee_user_id
                  ? 'is-danger'
                  : '',"message":_vm.getMachineValidationErrors &&
                _vm.getMachineValidationErrors.responsible_employee_user_id
                  ? _vm.getMachineValidationErrors.responsible_employee_user_id
                  : ''}},[_c('ResponsibleEmployeeSelect',{attrs:{"is-expanded":true},model:{value:(_vm.responsibleEmployee),callback:function ($$v) {_vm.responsibleEmployee=$$v},expression:"responsibleEmployee"}})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }