
import { Component, Prop, VModel, Watch } from "vue-property-decorator";
import { Customer, User } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { UserStoreMixin } from "@/mixins/Stores/UserStoreMixin";
import IUser from "@/mixins/interface/IUser";

@Component({
  components: {},
})
export default class ResponsibleEmployeeSelect extends mixins(UserStoreMixin) {
  @Prop({ required: false, default: "" })
  protected size!: string;

  @Prop({ required: false, default: false })
  protected isExpanded!: boolean;

  protected placeholder = "mplaceholder";

  protected name = "";

  @VModel()
  protected selectedUser!: IUser | null;

  @Watch("selectedUser", { immediate: true, deep: true })
  protected onSelectedUserChanged(user: IUser | null): void {
    this.name = user?.name ?? "";
  }

  protected load(): void {
    this.findAllUserItemsAction({
      resource: User.resource,
    });
  }

  protected onSelect(item: IUser): void {
    console.debug("on select", item);
    this.selectedUser = item;
    this.$emit("select", item);
  }

  protected created(): void {
    this.load();
  }
}
