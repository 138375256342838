import IProviderCompany from "@/interfaces/IProviderCompany";
import { ProviderCompanies } from "@/config/Consts";

export default class Consts {
  public static getProviderCompanyById(id: number): IProviderCompany | null {
    return (
      ProviderCompanies.find((providerCompany: IProviderCompany) => {
        return providerCompany.id === id;
      }) ?? null
    );
  }
}
