
import {
  Component,
  ModelSync,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import ICustomer from "@/interfaces/ICustomer";
import { Customer } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { BaseDataCustomerStoreMixin } from "@/mixins/Stores/BaseData/CustomerStoreMixin";

@Component({
  components: {},
})
export default class CustomerSelect extends mixins(BaseDataCustomerStoreMixin) {
  @Prop({ required: false, default: "" })
  protected size!: string;

  @Prop({ required: false, default: false })
  protected isExpanded!: boolean;

  protected placeholder = "mplaceholder";

  protected name!: ICustomer;

  protected selectedCustomerName: string | null = null;

  @VModel()
  protected selectedCustomer!: ICustomer | null;

  @Watch("selectedCustomer", { immediate: true, deep: true })
  protected onSelectedCustomerChanged(customer: ICustomer | null): void {
    this.selectedCustomerName = customer?.customer ?? "";
  }

  protected loadCustomers(): void {
    this.findAllBaseDataCustomerItemsAction({
      resource: Customer.resource,
    });
  }

  protected onSelect(customer: ICustomer): void {
    console.debug("on select", customer);
    this.selectedCustomer = customer;
    this.$emit("select", customer);
  }

  protected created(): void {
    this.loadCustomers();
  }
}
